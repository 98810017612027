import React from 'react'


// Hook
const useEventLock  = (contentId = "root-container") =>
{
    if (typeof window === 'undefined') {
        return null
    }
    const content = document.getElementById(contentId)
    return {
        on: ()=>{
            if( content ){
                content.style.pointerEvents = 'none'
            }
        },
        off: ()=>{
            if( content ){
                content.style.pointerEvents = 'auto'
            }
        }
    }
}

export { useEventLock }