import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import Link from "@components/Link"
import { useMenuLink } from "@hooks/useURL"
import { Pc, Sp } from "@hooks/useMediaQuery"
import LanguageButton from "@components/LanguageButton"

import css from "@css/components/blocks/global/HeaderSp.module.styl"
import LangJpSVG from "@images/GlobalHeader__btn__lange-jp.svg"
import LogoSvg from "@images/Global__logo__miraicomachi.svg"

export default function GlobalHeaderSp()
{
    const menuLinks = useMenuLink()

    return(
        <header className={css.container}>
            <div className={css.inner}>
                <Link className={css.logo} to='/'><LogoSvg/></Link>

                <div className={css.lange}>
                    <LanguageButton/>
                </div>
            </div>
        </header>
    )
}
