// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-3rd-anniversary-js": () => import("./../src/pages/3rd-anniversary.js" /* webpackChunkName: "component---src-pages-3rd-anniversary-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comics-character-js": () => import("./../src/pages/comics/character.js" /* webpackChunkName: "component---src-pages-comics-character-js" */),
  "component---src-pages-comics-js": () => import("./../src/pages/comics.js" /* webpackChunkName: "component---src-pages-comics-js" */),
  "component---src-pages-download-js": () => import("./../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-hotitems-js": () => import("./../src/pages/hotitems.js" /* webpackChunkName: "component---src-pages-hotitems-js" */),
  "component---src-pages-illust-js": () => import("./../src/pages/illust.js" /* webpackChunkName: "component---src-pages-illust-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labo-js": () => import("./../src/pages/labo.js" /* webpackChunkName: "component---src-pages-labo-js" */),
  "component---src-pages-linestickers-js": () => import("./../src/pages/linestickers.js" /* webpackChunkName: "component---src-pages-linestickers-js" */),
  "component---src-pages-songs-js": () => import("./../src/pages/songs.js" /* webpackChunkName: "component---src-pages-songs-js" */),
  "component---src-pages-vocaloid-js": () => import("./../src/pages/vocaloid.js" /* webpackChunkName: "component---src-pages-vocaloid-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-comics-js": () => import("./../src/templates/comics.js" /* webpackChunkName: "component---src-templates-comics-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

