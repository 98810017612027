// import React,{ useRef } from 'react'
// import * as THREE from 'three'
// import { useThree } from "react-three-fiber"
import 'mobx-react-lite/batchingForReactDom'
import { WEBGL } from 'three/examples/jsm/WebGL.js'

import {observable, action} from 'mobx'

class AppStore {
    @observable isPopstate = true
    @observable locomotiveScroll = null
    @observable isWebGl = WEBGL.isWebGLAvailable()

    @observable isLoadedModelData = false
    @observable isLoaded = false
    @observable isLoadedAnimated = false
    @observable isLoadedParticleAnimated = false

    @observable transitionCurrent = "none"

    @observable isOpenModalMenu = false
    @observable isNowModalAnimation = false
    @observable isModalMenuTransition = false
    @observable isModalMenuSmoothScroll = false

    @observable currentLang = 'ja'
    @observable contentModalData = null//bad
    @observable contentModalDataList = null
    @observable contentModalDataListCurrentId = null
    @observable isContentModal = false
    @observable isContentModalDetail = false

    @observable preScrollTop = 0
    @observable currentScrollTarget = 0
    @observable currentScrollTop = 0
    @observable currentScrollRaito = 0

    @action openModalMenu(){
        this.isOpenModalMenu = true
    }

    @action closeModalMenu(){
        this.isOpenModalMenu = false
    }

    //データの一部
    @action setContentModalData (data){
        this.contentModalData = data
    }
    //データのセット
    @action setContentModalDataList (data, id, isDetail = true){
        this.contentModalDataList = data
        this.contentModalDataListCurrentId = id
        this.isContentModalDetail = isDetail
    }

    @action loadedAnimated(){
        this.isLoadedAnimated = true
    }

    @action loadedParticleAnimated(){
        this.isLoadedParticleAnimated = true
    }

    @action loadedAnimated(){
        this.isLoadedAnimated = true
    }

}

export default AppStore