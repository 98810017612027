
import React from 'react'

import { useStores } from "@hooks/useStores"
// current : none start middle end
const duration = 1.6
const Status = React.createContext({
    current: 'none',
    startActions: [()=>{}],
    middleActions: [()=>{}],
    endActions: [()=>{}],
})

const useTransitionStatus = () => {
    const status = React.useContext(Status)
    return status
}

const useTransition = ({isStayEnd}) => {
    const { appStore } = useStores()
    // const [isOverStay, setIsOverStay ] = React.useState(false)
    // const [isStayed, setIsStayed] = React.useState(false)

    const d = duration * 1000
    const status = React.useContext(Status)

    const start = () => {
        //start
        console.log('===========start')
        appStore.isContentModal = false
        appStore.contentModalDataList = []
        appStore.transitionCurrent = "start"

        setTimeout(() => {
            console.log('===========middle')
            appStore.transitionCurrent = "middle"
            appStore.isModalMenuTransition = false
        }, d * 1)

        if( !isStayEnd ){
            setTimeout(() => {
                console.log('===========end')
                appStore.transitionCurrent = "end"
            }, d * 2)
        } else {
            setTimeout(() => {
                console.log('===========stay')
                appStore.transitionCurrent = "stay"
            }, d * 2)
        }
    }

    return {
        status: React.useContext(Status),
        duration: duration,
        start: start,
        stayStart: ()=>{
            let id = setInterval(()=>{
                if( appStore.transitionCurrent === "stay" ){
                    console.log('===========end')
                    appStore.transitionCurrent = "end"
                    clearInterval(id)
                    setTimeout(() => {
                        appStore.transitionCurrent = "none"
                    }, d/2)
                } else {
                    console.log('============ over')
                }
            }, 300)
        }
    }
}


export { useTransition, duration, Status }