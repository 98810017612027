import index from '@languages/ja/index.json'
import news from '@languages/ja/news.json'
import comics from '@languages/ja/comics.json'
import gallery from '@languages/ja/gallery.json'
import vocaloid from '@languages/ja/vocaloid.json'
import download from '@languages/ja/download.json'
import footerSns from '@languages/ja/footer-sns.json'
import footer from '@languages/ja/footer.json'
import error from '@languages/ja/error404.json'
import labo from '@languages/ja/labo.json'
import music from '@languages/ja/music.json'
import hotitems from '@languages/ja/hotitems.json'
import illust from '@languages/ja/illust.json'
import common from '@languages/ja/common.json'
import anniversary from '@languages/ja/anniversary.json'
import linestickers from '@languages/ja/linestickers.json'

const data = {
    ...index,
    ...news,
    ...comics,
    ...gallery,
    ...vocaloid,
    ...download,
    ...footerSns,
    ...footer,
    ...error,
    ...labo,
    ...music,
    ...hotitems,
    ...illust,
    ...common,
    ...anniversary,
    ...linestickers,
}
export default data