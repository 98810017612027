import React from "react"
import { motion, AnimatePresence } from 'framer-motion'
import LocomotiveScroll from 'locomotive-scroll'
import { useObserver } from "mobx-react-lite"
import loadable from '@loadable/component'
import browser from 'browser-detect'

import useLanguage from "@hooks/useLanguage"
import { useStores } from "@hooks/useStores"
import { useGsapPlugins } from "@hooks/useGsapPlugins"
import { useTransition, duration } from "@hooks/useTransitionStatus"
import { useEventListener } from "@hooks/useEventListener"

// import { TransitionPortal } from 'gatsby-plugin-transition-link'

// import HeaderId from '@components/HeaderId'
// import ShuffleButton from '@components/GlobalShuffleButton'
// import FooterInfo from '@components/FooterInfo'
// import Loading from '@components/GlobalLoading'
import Loading from '@components/Loading'
// import ContentModal from "@components/ContentModalSlider"
import Header from "@components/blocks/global/Header"
// import ThreeJs from '@three/Main.js'

import '@css/style.styl'

const _duration = 0.6
const delay = duration
const ease = { ease: [0.75, 0.0, 0.25, 1.00]}
const ThreeJs = loadable( () => import('@three/Main.js'))

const mainVars = {
    initial: {
        // position: 'relative',
        opacity: 0,
    },
    enter: {
        transition: {
            duration: _duration,
            delay: delay,
            // when: 'beforeChildren',
        },
        opacity: 1,
    },
    exit: {
        transition: { duration: _duration },
        opacity: 0,
    },
}

const coverVars = (isFirst) => {
    return{
        initial: {
            zIndex: 10,
            background: `#DF4661`,
            position: 'fixed',
            width: `100%`,
            height: `100%`,
            top: 0,
            left: 0,
            x: `${ isFirst ? '100%' : '0%'}`,//初期表示を早くするため
        },
        enter: {
            transition: {
                duration: _duration,
                delay: delay,
                when: 'afterChildren',
                ...ease,
            },
            x: `100%`,
        },
        exit: {
            transition: {
                duration: _duration,
                // tims: [duration/2,duration/2],
                ...ease,
            },
            x: [`-100%`,`0%`]
        }
    }
}

// Particleでトランジションを管理しているよ！
export default function TransitionLayout({children, location, ...props})
{
    // const [browsed, setBrowsed] = React.useState()
    let wrapRef = React.useRef()
    let containerRef = React.useRef()
    const { customEase01 } = useGsapPlugins()
    const transition = useTransition({isStayEnd: true})
    const [resize, setResize] = React.useState(0)
    const { appStore } = useStores()
    const locomotiveScroll = React.useRef()
    useLanguage()

    useEventListener('popstate', ()=>{
        if( appStore.isPopstate ){
            transition.start()
        }
    })

    useEventListener('resize', ()=>{
        setResize(resize + 1)
    })

    const browsed  = React.useRef(browser())
    let isWebGl = true
    if(  browsed.current.name === "ie" || !appStore.isWebGl ){
        isWebGl = false
    }

    React.useEffect(()=>{
        if( !isWebGl ){
            document.body.style.height = 'auto'
            document.body.style.overflow = 'auto'
            return
        }
        if( typeof window === 'undefined'){ return }
        
        wrapRef.current.classList.add('wrap-scroll')
        containerRef.current.classList.add('root-container-scroll')
        
        if( locomotiveScroll.current ){
            // locomotiveScroll.current.update()
            locomotiveScroll.current.update()
            return
        }
        
        // const LocomotiveScroll = require("locomotive-scroll").default
        locomotiveScroll.current = new LocomotiveScroll({
            el: containerRef.current,
            smooth: true,
            smoothMobile: true,
            lerp: 0.09,
            multiplier: 1.1,
            touchMultiplier: 1.9,
        })
        locomotiveScroll.current.update()
        // window.scroll = locomotiveScroll.current
        // locomotiveScroll.on('scroll', ()=>{

        // })

        locomotiveScroll.current.myHide = () => {
            const doms = document.getElementsByClassName('c-scrollbar')
            for( let dom of doms){
                dom.classList.add('hide-scrollbar')
            }
        }

        locomotiveScroll.current.myShow = () => {
            const doms = document.getElementsByClassName('c-scrollbar')
            for( let dom of doms){
                dom.classList.remove('hide-scrollbar')
            }
        }

        appStore.locomotiveScroll = locomotiveScroll

        return () => {
            if (locomotiveScroll) locomotiveScroll.current.destroy()
        }
    },[])

    React.useEffect(()=>{
        if( locomotiveScroll.current ){
            locomotiveScroll.current.update()
        }
    },[resize])


    return (
        <div id="wrap" ref={wrapRef}>
            {/* <ContentModal/> */}
            <Header/>
            <Loading/>
            { isWebGl ?
                <ThreeJs/>
                :
                ()=>{
                    appStore.isLoaded = true
                    return null
                }
            }
            <div id="root-container" className="root-container" ref={containerRef}>
                <AnimatePresence
                    onExitComplete={()=>{
                        // console.log(">>>>>>>>>>>>>onExitComplete--1")
                        setTimeout(() => {
                            // containerRef.current.scrollTo(0,0)
                            // appStore.transitionMiddle()
                            transition.stayStart()
                            appStore.currentScrollTop = 0
                            appStore.currentScrollRaito = 0
                        }, duration)
                    }}
                    exitBeforeEnter
                >
                    <motion.div
                        key={location.pathname}
                        variants={mainVars}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        {children}
                    </motion.div>
                </AnimatePresence>
                {/*<AnimatePresence
                    onExitComplete={()=>{
                        console.log(">>>>>>>>>>>>>onExitComplete--2")
                    }}
                    exitBeforeEnter
                >
                    <motion.div
                        key={`${location.pathname}-cover1`}
                        variants={coverVars(isRoot)}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                    </motion.div>
                </AnimatePresence>*/}
            </div>
        </div>
    )
}

function remakeChildren(children, newProps){
    return React.Children.map(
        children,
        (child) => {
            // console.info(typeof child, child)

            switch (typeof child) {
                case 'string':
                // 子要素がテキストノードだった場合はそのまま return
                return child

            case 'object':
                // React要素だった場合は newProps を渡す
                return React.cloneElement(child, newProps)

            default:
                // それ以外の場合はとりあえず null 返しとく
                return <div>error</div>
            }
        }
    )
}
