import React from "react"
import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import { useStores } from "@hooks/useStores"

//commone
import En from '@languages/import-en'
import Ja from '@languages/import-ja'

const LanguageContext = React.createContext()

const useLanguageContext = () => {
    const context = React.useContext(LanguageContext)
    return context
}

const useLanguage = () => {
    const { appStore } = useStores()
    React.useEffect(()=>{

    })
    const resources = {
        en: {
            translation: {...En}
        },
        ja: {
            translation: {...Ja}
        },
    }
    const lang = appStore.currentLang

    return React.useMemo(()=>i18n
        .use(initReactI18next)
        .init({
            lng: lang,
            fallbackLng: lang,
            debug: true,

            interpolation: {
                escapeValue: false,
            },

            react: {
                wait: true,
            },
            resources: resources,
        })
    ,[])
}

export default useLanguage