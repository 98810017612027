import React, { useEffect, useRef} from 'react'

// Hook
function useEventListener(eventName, handler, element = typeof window === "undefined" ? null : window)
{
    // Create a ref that stores handler
    const savedHandler = useRef()

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        if( !element ){
            return
        }
        savedHandler.current = handler
    }, [handler])

    useEffect( () => {
        if( !element ){
            return
        }
        // Make sure element supports addEventListener
        // On
        const isSupported = element && element.addEventListener
        if (!isSupported){
            console.log('no supported')
            return
        }
        // Create event listener that calls handler function stored in ref
        const eventListener = event => savedHandler.current(event)

        // Add event listener
        element.addEventListener(eventName, eventListener, true)
        // Remove event listener on cleanup
        return () => {
            element.removeEventListener(eventName, eventListener)
        }
        },
        [eventName, element] // Re-run if eventName or element changes
    )
}

export { useEventListener }