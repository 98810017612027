import React from "react"
import {useTranslation} from 'react-i18next'
import { useStores } from "@hooks/useStores"
import { useObserver } from "mobx-react"

import css from "@css/components/LanguageButton.module.styl"

import LangJpSVG from "@images/GlobalHeader__btn__lange-jp.svg"
import LangEnSVG from "@images/GlobalHeader__btn__lange-en.svg"

export default function LanguageButton()
{
    // const [currentLang, setCurrentLang] = React.useState('ja')

    const { appStore } = useStores()
    const [currentLang] = useObserver(()=>[
            appStore.currentLang
    ])
    const {i18n} = useTranslation()

    const onClickHandler = () => {
        appStore.currentLang = currentLang === 'ja' ? 'en' : 'ja'
    }

    React.useEffect(()=>{
        i18n.changeLanguage(currentLang)
    },[currentLang])

    return(
        <button className={css.container} onClick={onClickHandler}>
            { currentLang === "ja" ? <LangJpSVG/> : <LangEnSVG/> }
        </button>
    )
}