
import React from 'react'
import { gsap } from "gsap"
import { CustomEase } from "gsap/CustomEase"


function useGsapPlugins()
{
    gsap.registerPlugin(CustomEase)

    const plugins = React.useCallback(()=>{
        return {
            CustomEase: CustomEase,
            customEase01: CustomEase.create("custom", "M0,0 C0.356,-0.002 0.298,0.608 0.4,0.8 0.506,1 0.764,1 1,1"),
        }
    },[])

    return plugins
}

export { useGsapPlugins }