import React from "react"
import { useObserver } from "mobx-react-lite"
import { gsap } from "gsap"
import browser from 'browser-detect'

import { useTransition, duration } from "@hooks/useTransitionStatus"
import { useStores } from "@hooks/useStores"
import { useGsapPlugins } from "@hooks/useGsapPlugins"
import { useEventLock } from "@hooks/useEventLock"
import LoadingAnimation from "@components/LoadingAnimation"
import css from "@css/components/Loading.module.styl"

import LogoSvg from "@images/Global__logo__miraicomachi.svg"

export default function Loading()
{
    const eventLock = useEventLock()
    const { customEase01 } = useGsapPlugins()
    const { appStore } = useStores()
    const [
        isLoaded,
        isLoadedModelData,
        transitionCurrent,
        locomotiveScroll,
    ] = useObserver(()=>[
        appStore.isLoaded,
        appStore.isLoadedModelData,
        appStore.transitionCurrent,
        appStore.locomotiveScroll,
    ])
    const containerRef = React.useRef()
    const logoRef = React.useRef()
    // const transition = useTransition({isStayEnd: true})
    // const background = { background: !appStore.isWebGl ? "#FF7400" : "none" }
    const background = { background: "#DF4661" }


    const browsed  = React.useRef(browser())
    let isWebGl = true
    if(  browsed.current.name === "ie" || !appStore.isWebGl ){
        isWebGl = false
    }

    const hideAnimation = () => {
        gsap.to(containerRef.current,{
            duration: 0.3,
            // delay: 2.4,
            opacity: 0,
            display: 'none',
            onComplete: () => {
                appStore.loadedAnimated()
                eventLock.off()
                if( appStore.locomotiveScroll ){
                    appStore.locomotiveScroll.current.update()
                }
            },
        })
    }

    const showAnimation = (callback) => {
        gsap.to(containerRef.current, {
            duration: 0.3,
            // delay: 1.2,
            opacity: 1.0,
            display: 'block',
            onComplete: () => {
                appStore.loadedAnimated()
                eventLock.on()
                if( locomotiveScroll ){
                    locomotiveScroll.current.scrollTo(0, 0, 0)
                }
                callback && callback()
            },
        })
    }

    //loading
    React.useEffect(()=>{
        if( isWebGl ){
            // if( isLoaded && isLoadedModelData){
            if( isLoaded ){
                hideAnimation()
            }
        } else {
            setTimeout(()=>{
                hideAnimation()
            }, 1000)
        }
    },[isLoaded, isLoadedModelData])

    //transition
    React.useEffect(()=>{
        if( isWebGl ){
            switch(transitionCurrent){
                case "middle" :
                    showAnimation()
                    break
                case "end" :
                    hideAnimation()
                    break
            }
        } else {
            switch(transitionCurrent){
                case "start" :
                    showAnimation(()=>{
                        // appStore.transitionCurrent = 'end'
                    })
                    break
                case "end" :
                    hideAnimation()
                    break
            }
        }
    },[
        transitionCurrent
    ])

    // React.useEffect(()=>{
    //     if( isLoaded ){
    //         gsap.to(containerRef.current, 0.3,{
    //             delay: 0.3,
    //             opacity: 0,
    //             display: 'none',
    //             onComplete: () => {
    //                 appStore.loadedAnimated()
    //             }
    //         })
    //     }
    // },[isLoaded])

    // style={{ display: isLoaded ? 'none' : 'block'}}
    return(
        <React.Fragment>
            <div ref={containerRef} className={css.container} style={background}>
                <div className={css.content}>
                    <div className={css.block}>
                        <div ref={logoRef} className={css.logo}>
                            <LogoSvg/>
                        </div>
                        <LoadingAnimation/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}