import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"
import browser from 'browser-detect'
import { useObserver } from "mobx-react-lite"
import { gsap, Elastic, Power4 } from "gsap"

import Link from "@components/Link"
import { useURL, useMenuLink } from "@hooks/useURL"
import { useStores } from "@hooks/useStores"
// import { isPc, isSp } from "@hooks/useMediaQuery"
import LanguageButton from "@components/LanguageButton"
// import { useGsapPlugins } from '@hooks/useGsapPlugins'
import { useEventLock } from "@hooks/useEventLock"

import css from "@css/components/blocks/global/ModalMenu.module.styl"

// import LangJpSVG from "@images/GlobalModalMenu__btn__lange-jp.svg"
import LogoSvg from "@images/Global__logo__miraicomachi.svg"
import MenuButtonSVG from "@images/GlobalModalMenu__btn__menu.svg"
import CloseButtonSVG from "@images/GlobalModalMenu__btn__menu-close.svg"
import TwitterButtonSVG from "@images/GlobalModalMenu__btn__twitter.svg"
import YoutubeButtonSVG from "@images/GlobalModalMenu__btn__youtube.svg"

export default function GlobalModalMenu()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "GlobalHeader__img__bg.svg" }) {
                publicURL
            }
        }
    `)

    const eventLock = useEventLock()
    const [buttonMode, setButtonMode] = React.useState('open')
    const { appStore } = useStores()

    const browsed  = React.useRef(browser())
    let isWebGl = true
    if(  browsed.current.name === "ie" || !appStore.isWebGl ){
        isWebGl = false
    }

    const [
        isOpenModalMenu,
        isNowModalAnimation
    ] = useObserver(()=>[
        appStore.isOpenModalMenu,
        appStore.isNowModalAnimation
    ])
    // const [ customEase01 ] = useGsapPlugins()
    // const [isOpen,setIsOpen] = React.useState(false)
    const menuRef = React.useRef()
    const menuListRef = React.useRef()
    const menuButtonRef = React.useRef()
    const logoRef = React.useRef()
    const snsRef = React.useRef()
    const langRef = React.useRef()

    const urls = useURL()
    const menuLinks = useMenuLink()

    // const animationTimeline = React.useMemo( () => {
    //     return new TimelineMax({paused:true})
    //     .to(menuButtonRef.current, 0.3, {
    //         opacity: 1,
    //         ease: customEase01,
    //     })
    //     .add('a')
    //     .to(menuButtonRef.current, 0.3, {
    //         opacity: 0,
    //         ease: customEase01,
    //     })
    //     .add('b')
    // })


    const onClickHandler = ()=>{
        if(appStore.transitionCurrent !== "none" || isNowModalAnimation === true){
            return
        }
        appStore.isNowModalAnimation = true
        if( isOpenModalMenu ){
            // appStore.closeModalMenu()
            appStore.isOpenModalMenu = false
            if( appStore.locomotiveScroll ){
                appStore.locomotiveScroll.current.myShow()
            }
        } else {
            // appStore.openModalMenu()
            appStore.isOpenModalMenu = true
            if( appStore.locomotiveScroll ){
                appStore.locomotiveScroll.current.myHide()
            }
        }
    }

    const ani01 = (target, spd, delay, callback) => {
        gsap.fromTo(target, {
            duration: spd,
            opacity: 0,
            x:`100%`,
            skewX: -45,
        },{
            ease: Power4.easeOut,
            delay: delay,
            opacity: 1,
            x:`0%`,
            skewX: 0,
            onComplete: ()=>{
                callback && callback()
            }
        })
    }
    const ani02 = (target, spd, delay, callback) => {
        gsap.fromTo(target,{
            duration: spd,
            opacity: 1,
            x:`0%`,
            skewX: 0,
        },{
            duration: spd,
            ease: Power4.easeIn,
            delay: delay,
            opacity: 0,
            x:`-100%`,
            skewX: -45,
            onComplete: ()=>{
                callback && callback()
            }
        })
    }

    let background = isWebGl ? {} : {
        background: '#FF7400'
    }
    React.useEffect(()=>{
        const spd = 0.6
        const ease2 = Elastic.easeOut.config(1, 2.0)
        const ease1 = Power4.easeInOut
        if( isOpenModalMenu ){
            // eventLock.off()
            //open
            //button
            gsap.to(menuButtonRef.current, {
                duration: spd/2,
                ease: ease2,
                opacity: 0,
                scale: 0.5,
                onComplete: () => {
                    setButtonMode('close')
                    gsap.to(menuButtonRef.current,{
                        duration: spd/2,
                        ease: ease1,
                        delay: spd + 0.9,
                        opacity: 1.0,
                        scale: 1.0,
                        onComplete: () => {
                        }
                    })
                }
            })
            //container
            gsap.fromTo(menuRef.current,{
                duration: spd,
                display: 'none',
                x:`150%`,
                skewX: -15,
                ...background
            },{
                duration: spd,
                ease: Power4.easeOut,
                x:`0%`,
                display: 'block',
                skewX: 0,
            })

            //list
            let ci = 0
            for( let item of menuListRef.current.children ){
                let $child = item.querySelector(`.${css.child}`)
                if (!$child) {
                    gsap.fromTo(item, {
                        duration: spd,
                        opacity: 0,
                        x:`100%`,
                        skewX: -45,
                    },{
                        duration: spd,
                        ease: Power4.easeOut,
                        delay: spd + ci * 0.1,
                        opacity: 1,
                        x:`0%`,
                        display: 'block',
                        skewX: 0,
                    })
                    const cover = item.children[0]
                    gsap.fromTo(cover, {
                        duration: spd,
                        scaleX: 1.0,
                        transformOrigin: 'left',
                        display: 'block',
                    },{
                        duration: spd,
                        ease: Power4.easeOut,
                        delay: spd + ci * 0.15,
                        scaleX: 0,
                        display: 'none',
                    })

                } else {
                    let cy = 1
                    const label = item.children[0]
                    gsap.fromTo(label, {
                        duration: spd,
                        opacity: 0,
                        x: `100%`,
                    }, {
                        duration: spd,
                        ease: Power4.easeOut,
                        delay: spd + ci * 0.15,
                        opacity: 0.5,
                        x: `0%`,
                    })
                    for (let item of $child.children) {
                        gsap.fromTo(item, {
                            duration: spd,
                            opacity: 0,
                            x: `100%`,
                            skewX: -45,
                        }, {
                            duration: spd,
                            ease: Power4.easeOut,
                            delay: spd + ci * 0.1 + cy * 0.1,
                            opacity: 1,
                            x: `0%`,
                            display: 'block',
                            skewX: 0,
                        })
                        const cover = item.children[0]
                        gsap.fromTo(cover, {
                            duration: spd,
                            scaleX: 1.0,
                            transformOrigin: 'left',
                            display: 'block',
                        }, {
                            duration: spd,
                            ease: Power4.easeOut,
                            delay: spd + ci * 0.15 + cy * 0.1,
                            scaleX: 0,
                            display: 'none',
                        })
                        cy++
                    }
                }
                ci++
            }
            let d = spd + 0.15 * ci
            ani01(langRef.current, spd, d + 0.1)
            ani01(logoRef.current, spd, d + 0.2)
            ani01(snsRef.current.children[0], spd, d + 0.3)
            ani01(snsRef.current.children[1], spd, d + 0.4, () => {
                appStore.isNowModalAnimation = false
            })
        } else {
            //close
            //button
            // eventLock.on()
            gsap.to(menuButtonRef.current, {
                duration: spd/2,
                ease: ease2,
                opacity: 0,
                scale: 0.5,
                onComplete: () => {
                    setButtonMode('open')
                    gsap.to(menuButtonRef.current, spd/2, {
                        delay: spd + 0.9,
                        ease: ease1,
                        opacity: 1.0,
                        scale: 1.0,
                    })
                }
            })
            //container
            gsap.to(menuRef.current, {
                duration: spd,
                ease: Power4.easeIn,
                delay: spd + 0.3,
                x:`-150%`,
                skewX: -15,
                display: 'none',
                onComplete: () => {
                    appStore.isNowModalAnimation = false
                }
            })
            //list
            let ci = 0
            for( let item of menuListRef.current.children ){
                let $child = item.querySelector(`.${css.child}`)
                if (!$child) {
                    gsap.fromTo(item,{
                        duration: spd,
                        opacity: 1,
                        x:`0%`,
                        skewX: 0,
                    },{
                        duration: spd,
                        ease: Power4.easeIn,
                        delay: ci * 0.1,
                        opacity: 0,
                        x:`-100%`,
                        display: 'block',
                        skewX: -45,
                    })
                    
                    const cover = item.children[0]
                    gsap.fromTo(cover, {
                        duration: spd/2,
                        scaleX: 0.0,
                        transformOrigin: 'right',
                        display: 'block',
                    },{
                        duration: spd/2,
                        ease: Power4.easeIn,
                        delay: ci * 0.15,
                        scaleX: 1.0,
                    })
                } else {
                    let cy = 1
                    const label = item.children[0]
                    gsap.to(label,{
                        duration: spd,
                        ease: Power4.easeIn,
                        delay: ci * 0.15,
                        opacity: 0,
                        x: `-100%`,
                    })
                    for(let item of $child.children){
                        gsap.fromTo(item, {
                            duration: spd,
                            opacity: 1,
                            x: `0%`,
                            skewX: 0,
                        }, {
                            duration: spd,
                            ease: Power4.easeIn,
                            delay: ci * 0.1 + cy * 0.1,
                            opacity: 0,
                            x: `-100%`,
                            display: 'block',
                            skewX: -45,
                        })

                        const cover = item.children[0]
                        gsap.fromTo(cover, {
                            duration: spd / 2,
                            scaleX: 0.0,
                            transformOrigin: 'right',
                            display: 'block',
                        }, {
                            duration: spd / 2,
                            ease: Power4.easeIn,
                            delay: ci * 0.15 + cy * 0.1,
                            scaleX: 1.0,
                        })
                        cy++
                    }
                }
                ci++
            }
            let d = 0.15
            ani02(langRef.current, spd, d + 0.4, () => {
                appStore.isNowModalAnimation = false
            })
            ani02(logoRef.current, spd, d + 0.3)
            ani02(snsRef.current.children[0], spd, d + 0.2)
            ani02(snsRef.current.children[1], spd, d + 0.1)
        }
    },[isOpenModalMenu])

    return(
        <React.Fragment>
            <div ref={menuRef} className={css.container} style={{display: 'none'}}>
                <div className={css.inner}>
                    <div className={css.logo} ref={logoRef}><Link to='/'><LogoSvg/></Link></div>
                    <div className={css.lange} ref={langRef}>
                        <LanguageButton/>
                    </div>
                    <div className={css.sns} ref={snsRef}>
                        <Link className={css.twitterButton} to={urls.twitter} target="_blank">
                            <TwitterButtonSVG/>
                        </Link>
                        <Link className={css.youtubeButton} to={urls.youtube} target="_blank">
                            <YoutubeButtonSVG/>
                        </Link>
                    </div>
                    <nav className={css.menu}>
                        <ul className={css.list} ref={menuListRef}>
                            {
                                menuLinks.map(({ label, to, target, child }, i)=>{
                                    if (child) {
                                        return (
                                            <li key={i} className={css.item2}>
                                                {/* <span className={css.cover} /> */}
                                                <span className={css.label}>{label}</span>
                                                <ul className={css.child}>
                                                    {child.map(({ label, to, target }, i) => {
                                                        return (
                                                            <li key={i}>
                                                                <span className={css.cover} />
                                                                <Link to={to} scrollTarget={target}>{label}</Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li className={css.item} key={i}>
                                                <span className={css.cover} />
                                                <Link to={to} scrollTarget={target}>{label}</Link>
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </nav>
                </div>
            </div>
            <div ref={menuButtonRef} className={css.menuButton} onClick={onClickHandler}>
                { buttonMode === "open" ?
                    <MenuButtonSVG/>
                    :
                    <CloseButtonSVG/>
                }
            </div>
        </React.Fragment>
    )
}


