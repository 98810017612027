import React from 'react'
import { navigate } from 'gatsby-link'
import { globalHistory } from '@reach/router'
import Browser from 'browser-detect'
// import scrollTo from 'gatsby-plugin-smoothscroll';
// import { animateScroll as scroll } from 'react-scroll'
import { SmoothScroll } from '@hooks/useSmoothScroll'
import { useStores } from '@hooks/useStores'

import { useTransition } from "@hooks/useTransitionStatus"

export default function Link({
	children,
	onClick = ()=>{},
	to = null,
	scrollTarget = null,
	delay = 0,
	target,
	disableMode = false,
	isHtml = false,
	...props})
{
	const transition = useTransition({isStayEnd: true})
	const browser = React.useRef(Browser())
	const { appStore } = useStores()

	let { className, key } = props

	let offset = -100
	if( browser.current.mobile ) {
		offset = -64
	}


	const handleClick = (e) => {
		e.stopPropagation()
		e.preventDefault()
		if(appStore.transitionCurrent !== "none"){
		    return
		}

		let pathname = globalHistory.location ? globalHistory.location.pathname : null

		if(  pathname === to ){
			//遷移させない
			if( scrollTarget ){
				// navigate(`${to}${scrollTarget}`)
				if(appStore.isOpenModalMenu){
					appStore.isNowModalAnimation = true
					appStore.isOpenModalMenu = false
				}
				
				if( appStore.locomotiveScroll ){
                    appStore.locomotiveScroll.current.scrollTo(scrollTarget, offset, 0.6)
                } else {
					SmoothScroll(appStore,scrollTarget)
				}
			}
		} else {
			//遷移する
			if(appStore.isOpenModalMenu){
				appStore.isModalMenuTransition = true
				appStore.isOpenModalMenu = false
			}
			transition.start()

			if( onClick ) {
				onClick(e)
			}
			if( !scrollTarget ){
				setTimeout(() => {
					navigate(to)
				}, delay)
			} else {
				setTimeout(() => {

					navigate(`${to}`)
					setTimeout(() => {
						if( appStore.locomotiveScroll ){
							appStore.locomotiveScroll.current.scrollTo(scrollTarget, offset, 0.6)
						} else {
							SmoothScroll(appStore,scrollTarget)
						}
					}, 3000)
				}, delay)
			}
		}

	}

	// const [isDisable, setIsDisable] = React.useState(false)

	// React.useEffect(()=>{
	// 	let pathname = globalHistory.location ? globalHistory.location.pathname : null
	// 	if( disableMode === false ){
	// 		setIsDisable(true)
	// 	}
	// 	if( pathname === to || pathname === `/recruit${to}` || pathname === `/recruit${to}/` ){
	// 		setIsDisable(true)
	// 	} else {
	// 		setIsDisable(false)
	// 	}
	// })

	return (
		<React.Fragment>
			{ to && !to.match(/http/) ?
				!isHtml ?
					<a
						key={key || null}
						href={to}
						className={`${className ? className : ``}`}
						onClick={e => handleClick(e)}
					>
						{ children }
					</a>
					:
					<a
						key={key || null}
						href={to}
						className={`${className ? className : ``}`}
						onClick={e => handleClick(e)}
						dangerouslySetInnerHTML={{__html: children}}
					></a>
				:
				!isHtml ?
					<a className={`${className ? className : ``}`} href={to} target={target}
						rel="noopener noreferrer"
					>
						{ children }
					</a>
					:
					<a className={`${className ? className : ``}`} href={to} target={target}
						rel="noopener noreferrer"
						dangerouslySetInnerHTML={{__html: children}}
					></a>
			}
		</React.Fragment>
	)
}